import React, { useEffect, useContext } from "react"
import { UserContext } from "../context/userContext"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import ToggleMute from "../components/ToggleMute"

export default function Menu() {
  const { user, saveUser } = useContext(UserContext)

  useEffect(() => {
    console.log(user)
  }, [])
  return (
    <Background>
      <div className="d-flex flex-column">
        <h1 className="display-3 font-weight-bold ml-4 mt-4">Welcome</h1>

        <h1 className="display-5 font-weight-bold ml-4 mt-4">
          Please select your option
        </h1>

        <div className="p-4 d-flex">
          <Button
            text="Job Application"
            onClick={() => {
              navigate("/job-application-start")
            }}
          />
          {/* <Button text="Salary Collection" /> */}
        </div>

        <div className="d-flex flex-row-reverse fixed-top p-4">
          <ToggleMute
            isActive={user.isSoundActive}
            onClick={() => {
              saveUser({
                ...user,
                isSoundActive: !user.isSoundActive,
              })
              if (user.isSoundActive) {
                user.audio && user.audio.pause && user.audio.pause()
              } else {
                user.audio && user.audio.play && user.audio.play()
              }
            }}
          />
        </div>

        {/* <div className="p-4 d-flex">
          <Button text="Ask Questions" />
          <Button text="Talk to Us" />
        </div> */}
      </div>
    </Background>
  )
}
